var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"card auth-card",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}}},[_c('div',{staticClass:"card-content"},[_c('span',{staticClass:"card-title"},[_vm._v(_vm._s(_vm._f("localizeFilter")("HomeBookkeeping")))]),_c('div',{staticClass:"input-field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],class:{
          invalid:
            (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
            (_vm.$v.email.$dirty && !_vm.$v.email.email)
        },attrs:{"id":"email","type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),(_vm.$v.email.$dirty && !_vm.$v.email.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("EnterEmail"))+" ")]):(_vm.$v.email.$dirty && !_vm.$v.email.email)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("CorrectEmail"))+" ")]):_vm._e()]),_c('div',{staticClass:"input-field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],class:{
          invalid:
            (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
            (_vm.$v.password.$dirty && !_vm.$v.password.minLength)
        },attrs:{"id":"password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm._f("localizeFilter")("Password")))]),(_vm.$v.password.$dirty && !_vm.$v.password.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(_vm._s(_vm._f("localizeFilter")("EnterPassword")))]):_vm._e(),(_vm.$v.password.$dirty && !_vm.$v.password.minLength)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(_vm._s(_vm._f("localizeFilter")("PasswordLimitMore"))+" "+_vm._s(_vm.$v.password.$params.minLength.min))]):_vm._e()]),_c('div',{staticClass:"input-field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name),expression:"name",modifiers:{"trim":true}}],staticClass:"validate",class:{
          invalid: _vm.$v.name.$dirty && !_vm.$v.name.required
        },attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm._f("localizeFilter")("UserName")))]),(_vm.$v.name.$dirty && !_vm.$v.name.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(_vm._s(_vm._f("localizeFilter")("EnterUserName")))]):_vm._e()]),_c('p',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agree),expression:"agree"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.agree)?_vm._i(_vm.agree,null)>-1:(_vm.agree)},on:{"change":function($event){var $$a=_vm.agree,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agree=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agree=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agree=$$c}}}}),_c('span',[_vm._v(_vm._s(_vm._f("localizeFilter")("RulesAgree")))])])])]),_c('div',{staticClass:"card-action"},[_c('div',[_c('button',{staticClass:"btn waves-effect waves-light auth-submit",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("Registration"))+" "),_c('i',{staticClass:"material-icons right"},[_vm._v("send")])])]),_c('p',{staticClass:"center"},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("HaveAccount"))+" "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm._f("localizeFilter")("Enter")))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }